import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import Logo from "../images/MD Logo Colour.svg"
import ChevronDown from "../images/chevron-down"

const HeaderDesktop = () => {
  const [headerCollapsed, setHeaderCollapsed] = useState(false)
  const [servicesExpanded, setServicesExpanded] = useState(false)
  const [investmentExpanded, setInvestmentExpanded] = useState(false)

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setHeaderCollapsed(true)
    } else {
      setHeaderCollapsed(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [])

  // Ref to select container to collapse it when clicking outside it
  const serviceNode = useRef()
  const investmentNode = useRef()

  // useEffect to add click event listener to collapse select
  useEffect(() => {
    document.addEventListener("mousedown", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  const handleClick = event => {
    if (!serviceNode.current.contains(event.target)) {
      setServicesExpanded(false)
    }
    if (!investmentNode.current.contains(event.target)) {
      setInvestmentExpanded(false)
    }
  }

  return (
    <HeaderStyled collapsed={headerCollapsed}>
      <LogoStyled
        src={Logo}
        collapsed={headerCollapsed}
        alt="Logo"
      ></LogoStyled>
      <HeaderItem
        style={{ marginLeft: "auto" }}
        href="https://www.masterdrilling.com/"
      >
        HOME
      </HeaderItem>
      <HeaderItem href="https://www.masterdrilling.com/about-us/">
        ABOUT US
      </HeaderItem>
      <HeaderItem href="https://www.masterdrilling.com/our-people/">
        OUR PEOPLE
      </HeaderItem>
      <HeaderItem
        onClick={() => {
          setServicesExpanded(servicesExpanded ? false : true)
        }}
        expanded={servicesExpanded}
        ref={serviceNode}
      >
        SERVICES
        <ChevronDown />
        <SubMenu expanded={servicesExpanded} ref={serviceNode}>
          <MenuItem href="https://www.masterdrilling.com/services/raise-boring/">
            Raise Boring
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/#rockboring">
            Rock Boring
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/#supportservices">
            Support Services
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/mobile-tunnel-borer/">
            Mobile Tunnel Boring
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/case-studies-and-projects/">
            Case Studies And Projects
          </MenuItem>
        </SubMenu>
      </HeaderItem>
      <HeaderItem
        onClick={() => {
          setInvestmentExpanded(investmentExpanded ? false : true)
        }}
        expanded={investmentExpanded}
        ref={investmentNode}
      >
        EXPLORATION DRILLING SERVICES
        <ChevronDown />
        <SubMenu expanded={investmentExpanded} ref={investmentNode}>
          <MenuItem href="https://www.masterdrilling.com/services/exploration-drilling-services/aircore-drilling/">
            Aircore Drilling
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/exploration-drilling-services/blast-hole-drilling/">
            Blast Hole Drilling
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/exploration-drilling-services/core-drilling/">
            Core Drilling
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/exploration-drilling-services/hydrogeological/">
            Hydrogeological
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/exploration-drilling-services/grade-control-drilling/">
            Grade Control Drilling
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/exploration-drilling-services/mud-rotary-drilling/">
            Mud Rotary Drilling
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/exploration-drilling-services/percussion-drilling/">
            Precussion Drilling
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/exploration-drilling-services/sonic-drilling/">
            Sonic Drilling
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/exploration-drilling-services/underground-drilling/">
            Underground Drilling
          </MenuItem>
        </SubMenu>
      </HeaderItem>
      <HeaderItem href="/">INVESTMENT &amp; MULTIMEDIA</HeaderItem>
      <HeaderItem href="https://www.masterdrilling.com/contact/">
        CONTACT
      </HeaderItem>
    </HeaderStyled>
  )
}

export default HeaderDesktop

// ====================
//        STYLES
// ====================

const HeaderStyled = styled.header`
  position: fixed;
  width: 100%;
  z-index: 2;
  height: ${({ collapsed }) => (collapsed === true ? 75 : 165)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 0;
  background-color: white;
  transition: height 0.3s ease-out;
  padding-right: 20px;
`
const LogoStyled = styled.img`
  margin-bottom: 0;
  margin-left: ${({ collapsed }) => (collapsed === true ? 12 : 20)}px;
  height: ${({ collapsed }) => (collapsed === true ? 50 : 120)}px;
  transition: all 0.3s ease-out;
`
const HeaderItem = styled.a`
  color: black;
  position: relative;
  font: normal normal normal 15px/20px Open Sans;
  margin: 0 25px;
  text-decoration: none;
  cursor: pointer;

  svg {
    width: 13px;
    margin-left: 5px;

    transform: rotate(
      ${({ expanded }) => (expanded === true ? "180" : "0")}deg
    );
  }

  @media (max-width: ${({ theme }) => theme.desktopSmall}) {
    margin: 0 15px;
  }
`
const MenuItem = styled.a`
  display: flex;
  height: 60px;
  min-height: 60px;
  border-bottom: 1px solid #dddddd;
  justify-content: center;
  align-items: center;
  font: normal normal normal 15px/20px Open Sans;
  cursor: pointer;
  text-decoration: none;
  color: black;
`

const SubMenu = styled.div`
  position: absolute;
  display: ${({ expanded }) => (expanded === true ? "flex" : "none")};
  flex-direction: column;
  width: 300px;
  height: fit-content;
  background-color: lightGrey;
  right: calc(50% - 150px);
  margin-top: 10px;
`
