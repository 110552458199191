/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import HeaderDesktop from "./header-desktop"
import HeaderMobile from "./header-mobile"
import "./layout.css"
import ThemeConfig from "./theme-config"
import Footer from "./footer"

const Layout = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
  }, [])

  return (
    <ThemeConfig>
      {windowWidth > 1400 ? <HeaderDesktop /> : <HeaderMobile />}
      <main>{children}</main>
      <Footer></Footer>
    </ThemeConfig>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
