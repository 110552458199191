import React, { useEffect, useState } from "react"
import styled from "styled-components"

import Logo from "../images/MD Logo Colour.svg"
import ChevronDown from "../images/chevron-down"
import Cross from "../images/Icon feather-x.svg"
import MenuIcon from "../images/Icon feather-menu.svg"

const HeaderMobile = () => {
  const [menuExpanded, setMenuExpanded] = useState(false)
  const [servicesExpanded, setServicesExpanded] = useState(false)
  const [investmentExpanded, setInvestmentExpanded] = useState(false)

  useEffect(() => {
    setServicesExpanded(false)
    setInvestmentExpanded(false)
  }, [menuExpanded])

  return (
    <HeaderStyled>
      <HeaderContainer>
        <LogoStyled src={Logo}></LogoStyled>
        <img
          alt="Logo"
          style={{ marginLeft: "auto", marginBottom: "0" }}
          src={menuExpanded ? Cross : MenuIcon}
          onClick={() => {
            setMenuExpanded(menuExpanded ? false : true)
          }}
        ></img>
      </HeaderContainer>
      <HeaderMenu expanded={menuExpanded}>
        <MenuItem href="https://www.masterdrilling.com/">HOME</MenuItem>
        <MenuItem href="https://www.masterdrilling.com/about-us/">
          ABOUT US
        </MenuItem>
        <MenuItem href="https://www.masterdrilling.com/our-people/">
          OUR PEOPLE
        </MenuItem>
        <MenuItem
          onClick={() => {
            setServicesExpanded(servicesExpanded ? false : true)
          }}
          expanded={servicesExpanded}
        >
          SERVICES
          <ChevronDown />
        </MenuItem>
        <HeaderMenu expanded={servicesExpanded}>
          <MenuItem href="https://www.masterdrilling.com/services/raise-boring/">
            Raise Boring
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/#rockboring">
            Rock Boring
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/#supportservices">
            Support Services
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/mobile-tunnel-borer/">
            Mobile Tunnel Boring
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/case-studies-and-projects/">
            Case Studies And Projects
          </MenuItem>
        </HeaderMenu>
        <MenuItem
          onClick={() => {
            setInvestmentExpanded(investmentExpanded ? false : true)
          }}
          expanded={investmentExpanded}
        >
          EXPLORATION DRILLING SERVICES
          <ChevronDown />
        </MenuItem>
        <HeaderMenu expanded={investmentExpanded}>
          <MenuItem href="https://www.masterdrilling.com/services/exploration-drilling-services/aircore-drilling/">
            Aircore Drilling
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/exploration-drilling-services/blast-hole-drilling/">
            Blast Hole Drilling
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/exploration-drilling-services/core-drilling/">
            Core Drilling
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/exploration-drilling-services/hydrogeological/">
            Hydrogeological
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/exploration-drilling-services/grade-control-drilling/">
            Grade Control Drilling
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/exploration-drilling-services/mud-rotary-drilling/">
            Mud Rotary Drilling
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/exploration-drilling-services/percussion-drilling/">
            Precussion Drilling
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/exploration-drilling-services/sonic-drilling/">
            Sonic Drilling
          </MenuItem>
          <MenuItem href="https://www.masterdrilling.com/services/exploration-drilling-services/underground-drilling/">
            Underground Drilling
          </MenuItem>
        </HeaderMenu>
        <MenuItem>INVESTMENT &amp; MULTIMEDIA</MenuItem>
        <MenuItem>CONTACT</MenuItem>
      </HeaderMenu>
    </HeaderStyled>
  )
}

export default HeaderMobile

// ====================
//        STYLES
// ====================

const HeaderStyled = styled.header`
  width: 100%;
  z-index: 2;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: white;
`
const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 20px;
  min-height: 100px;
  height: fit-content;
`
const LogoStyled = styled.img`
  margin-bottom: 0;
  margin-left: 15px;
  height: 75px;
  transition: all 0.3s ease-out;
`
const MenuItem = styled.a`
  display: flex;
  height: 60px;
  min-height: 60px;
  border-bottom: 1px solid #dddddd;
  justify-content: center;
  align-items: center;
  font: normal normal normal 15px/20px Open Sans;
  color: black;

  svg {
    width: 13px;
    margin-left: 10px;

    transform: rotate(
      ${({ expanded }) => (expanded === true ? "180" : "0")}deg
    );
  }
`
const HeaderMenu = styled.div`
  background-color: white;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  max-height: ${({ expanded }) => (expanded === true ? "1350px" : "0")};

  & > a {
    text-decoration: none;
  }
`
