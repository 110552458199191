import React from "react"
import styled from "styled-components"

import Logo from "../images/MD Logo White.svg"
import Twitter from "../images/Twitter.svg"
import LinkedIn from "../images/LinkedIn.svg"

const Footer = () => {
  return (
    <>
      <FooterStyled>
        <img src={Logo} alt="Logo"></img>
        <LinkSection style={{ alignItems: "flex-end" }} className="quick-links">
          <p
            style={{
              textAlign: "right",
              width: "fit-content",
              marginRight: "25px",
            }}
          >
            QUICK LINKS
          </p>
        </LinkSection>
        <LinkSection>
          <a href="https://www.masterdrilling.com/about-us/">COMPANY PROFILE</a>
          <a href="https://www.masterdrilling.com/our-people/">
            WORK FOR MASTER DRILLING
          </a>
          <a href="https://www.masterdrilling.com/about-us/#milestones">
            KEY MILESTONES
          </a>
          <a href="https://www.masterdrilling.com/our-people/#directors">
            BOARD OF DIRECTORS
          </a>
        </LinkSection>
        <LinkSection>
          <a href="https://www.masterdrilling.com/our-people/#generalmanagers">
            REGIONAL MANAGERS
          </a>
          <a href="https://www.masterdrilling.com/about-us/#she">
            SUSTAINABILITY
          </a>
          <a href="https://www.masterdrilling.com/investment-multimedia/">
            INVEST
          </a>
          <a href="https://www.masterdrilling.com/contact/">CONTACT US</a>
        </LinkSection>
        <LinkSection
          style={{ border: "none", width: "150px" }}
          className="icons"
        >
          <p style={{ width: "fit-content" }}>FOLLOW US</p>
          <SocialIconContainer>
            <SocialIcon color="#54ABED">
              <a href="https://twitter.com/MDrillingGroup">
                <img src={Twitter} alt="Twitter"></img>
              </a>
            </SocialIcon>
            <SocialIcon color="#007CC5">
              <a href="https://www.linkedin.com/company/master-drilling-pty-ltd/">
                <img src={LinkedIn} alt="LinkedIn"></img>
              </a>
            </SocialIcon>
          </SocialIconContainer>
        </LinkSection>
      </FooterStyled>
      <CopyrightSection>
        © 2021 Master Drilling | Powered By SwiftCom
      </CopyrightSection>
    </>
  )
}

export default Footer

// ====================
//        STYLES
// ====================

const FooterStyled = styled.div`
  display: flex;
  width: 100%;
  height: 200px;
  background-color: #112952;
  align-items: center;
  justify-content: center;

  & > img {
    margin-left: 20px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    flex-direction: column;
    height: fit-content;

    & > img {
      margin: 50px 0;
      margin-bottom: 20px;
    }
  }
`
const CopyrightSection = styled.div`
  width: 100%;
  height: 45px;
  background-color: #3d4547;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 15px/20px Open Sans;
  color: white;
`
const LinkSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  border-right: 1px solid #007cc5;
  margin-left: 25px;
  height: 150px;

  & > a,
  p {
    font: normal normal normal 15px/18px Open Sans;
    color: white;
    margin: 5px;
    width: 200px;
    text-decoration: none;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    border: none;
    justify-content: center;
    align-items: center;
    margin: 0;
    height: fit-content;
    width: fit-content;

    & > a,
    p {
      text-align: center !important;
      margin: 10px 0 !important;
    }

    &.icons {
      margin: 30px 0;
    }

    &.quick-links {
      margin: 30px 0;

      & > a,
      p {
        font: normal normal bold 21px/28px Open Sans;
      }
    }
  }
`

const SocialIconContainer = styled.div`
  display: flex;
`

const SocialIcon = styled.div`
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
  border-radius: 100%;
  margin: 6px;
`
