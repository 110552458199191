import React from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"

const colors = {
  lightBlue: "#193B6B",
  darkBlue: "#021F38",
  red: "#BE2226",
}

export const windowWidths = {
  desktopHD: 1920,
  desktopStandard: 1366,
  desktopSmall: 1240,
  tabletLarge: 960,
  tabletSmall: 768,
  mobileLarge: 480,
  mobileStandard: 375,
}

const theme = {
  ...colors,
  desktopHD: `${windowWidths.desktopHD}px`,
  desktopSmall: `${windowWidths.desktopSmall}px`,
  desktopStandard: `${windowWidths.desktopStandard}px`,
  tabletLarge: `${windowWidths.tabletLarge}px`,
  tabletSmall: `${windowWidths.tabletSmall}px`,
  mobileLarge: `${windowWidths.mobileLarge}px`,
  mobileStandard: `${windowWidths.mobileStandard}px`,
}

const GlobalStyle = createGlobalStyle``

const ThemeConfig = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  )
}

export default ThemeConfig
